<template>
  <div
    v-if="isOpen"
    class="fixed bottom-0 right-0 lg:w-1/3 w-full z-50 bg-gray-100 lg:shadow-2xl lg:m-10"
  >
    <div class="p-5">
      <div class="font-sans">
        <h4 class="text-xl font-bold">
          &#x1F36A; Ciasteczka
        </h4>
        <p class="text-justify">
          Na stronie nie są przechowywane żadne dane osobowe. Używamy ciasteczek
          tylko do śledzenia odwiedzin na naszej stronie internetowej.
        </p>
        <div class="flex justify-end">
          <button
            class="hover:bg-green-500 text-green-900 hover:text-white p-1 border border-green-900 hover:border-transparent rounded"
            @click="accept"
          >
            Akceptuje ciasteczka
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieMessage",
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      if (process.browser) {
        return localStorage.getItem("GDPR:accepted", true);
      }
    },
    accept() {
      if (process.browser) {
        this.isOpen = false;
        localStorage.setItem("GDPR:accepted", true);
      }
    },
  },
};
</script>
